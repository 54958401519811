<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>系统管理</el-breadcrumb-item>
                    <el-breadcrumb-item>模拟登陆短信</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <el-form ref="form" :model="form" label-width="180px">
            <div class="admin_main_block admin_m15">
                <el-col :span="24">
                    <span class="grid-divider">模拟登陆短信</span>
                    <el-divider></el-divider>
                </el-col>
                <el-row>
                    <el-col :span="span" class="hight">
                        <el-form-item label="手机号码">
                            <el-input @blur="getSmsCode" placeholder="输入手机号" v-model="phone" :disabled="disabled" ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="span" class="hight">
                        <el-form-item label="验证码">
                            <el-input placeholder="" v-model="form.smsCode" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                disabled: false,
                span: 8,
                phone:'',
                form: {

                },
                where: {
                    "phoneNumber": "",
                },
            };
        },
        methods: {
            back() {
                this.$router.go(-1);
            },
            getSmsCode() {
                if (this.$isEmpty(this.phone)) {
                    return false;
                }
                this.where.phoneNumber = this.phone;
                this.$post(this.$api.simulateLoginSMS, this.where).then(
                    (res) => {
                        if (res.code === 1) {
                            // this.form.smsCode = res.data;
                            // this.form = {...this.form};
                            this.$set(this.form, 'smsCode', res.data);
                        } else {
                            this.$message({
                                type: "error",
                                message: res.msg,
                            });
                        }
                    }
                );
            },
        },
        created() {
        },
    };
</script>
<style lang="scss" scoped>
    .el-card {
        text-align: right;
        margin-top: 30px;
    }

    .hight {
        /*height: 60px;*/
    }

    .el-select {
        width: 100%;
    }

    .el-input {
        width: 100%;
    }

    .el-divider--horizontal {
        margin: 12px 0 12px 0;
    }

    .price {
        width: 200px;
    }

    .el-input-number--small {
        width: 200px;
    }
</style>
